import { DMProfilePic } from '@decormatters/dm-theme'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import {
  AvatarAccessory,
  AvatarAndName,
  AvatarWrapper,
  BalancePlank,
  BeforeText,
  CloseButton,
  Container,
  CountdownLabel,
  Dimmer,
  MembershipAds,
  MembershipPlank,
  MobileBalancePlank,
  MobileWelcomeAndCountdown,
  MobileWelcomeLine,
  ModalContainer,
  ModalTitle,
  MyBalanceLabel,
  ProgressBar,
  ProgressInnerBar,
  ProgressLabel,
  SpecialTaskCard,
  SubmitButton,
  TaskButton,
  TaskCard,
  TaskIcon,
  TaskListHeader,
  TaskMetadata,
  TasksContainer,
  TasksList,
  TasksTitle,
  TaskTitle,
  UserInfoPanel,
  Username
} from './DailyRewardsModal.styles'
import dcoinIcon from '../../assets/coin.svg'
import { nFormatter } from '../../utils/formatPrice'
import CheckInTimer from './CheckInTimer'
import cupIcon from '../../assets/dailyRewards/cup.svg'
import specialTaskIcon from '../../assets/dailyRewards/specialTask.svg'
import pencilIcon from '../../assets/dailyRewards/pencil.svg'
import followIcon from '../../assets/dailyRewards/follow.svg'
import giftIcon from '../../assets/dailyRewards/gift.svg'
import likeIcon from '../../assets/dailyRewards/like.svg'
import paletteIcon from '../../assets/dailyRewards/palette.svg'
import rollerIcon from '../../assets/dailyRewards/roller.svg'
import checkmarkIcon from '../../assets/checkmark.svg'
import checkInImg from '../../assets/dailycheckin.svg'
import { ReactComponent as MembershipAdIcon } from '../../assets/dailyRewards/membershipAdIcon.svg'
import { ReactComponent as MemberPlank } from '../../assets/dailyRewards/memberPlank.svg'
import { ReactComponent as StarterPlank } from '../../assets/dailyRewards/starterPlank.svg'
import { claimDailyTaskReward, claimWelcomeGiftReward, closeModal, openFollowModal, setShowUsernameModal } from '../../redux/communityReducers/CheckInReducer'
import { setShowMembershipModal, setShowPurchaseCoins } from '../../redux/designerReducers/designerReducer'
import { logEvent } from '../../utils/firebase'
import FollowDesigners from '../checkin/FollowDesigners'
import closeIcon from '../../assets/close.svg'
import { useIsMobile } from '../../hooks/useIsMobile'
import { RewardModal } from './RewardModal'
import { useIsTablet } from '../../hooks/useIsTablet'
import VerifyEmailSimpleModal from '../auth/VerifyEmailSimpleModal'
import { getRecommendedUsers } from '../../redux/communityReducers/CheckInReducer'
import { useIsTutorial } from '../../hooks/useIsTutorial'
import { VoteModal } from '../VoteModal/VoteModal'
import { getUserAvatar } from '../../utils/getUserAvatar'
import { useDailyRewardsInfo } from '../../hooks/useDailyRewardsInfo'
import { getMembershipType } from '../../utils/getMembershipType'
import { fetchFeed, setAttr } from '../../redux/feedInfiniteSlice'
import { FEED_RESET } from '../../redux/constants'
import moment from 'moment'
import { getTemplates } from '../../redux/apis'

const TASK_ICONS = {
  ffTFgusCfz: cupIcon, // Daily design
  dIEQgLxtpw: paletteIcon, // New User Design design
  rcIEBNmaSr: paletteIcon, // Limited design
  '1axAkTN3zc': likeIcon, // Like design
  Xr4CyGw6Jb: giftIcon, // Send gift
  kmEXaEWYFG: followIcon, // Follow user
  KC4XeteGcx: rollerIcon, // Event Designer
  OUv6BrETWp: paletteIcon, // Template design,
  '8GORtOEbeF': pencilIcon, // Create username
  jrY1WqDHjS: likeIcon // Vote Designs
}

export const DailyRewardsModal = () => {
  const isTutorial = useIsTutorial()
  const tutorialSeen = useSelector(state => state?.profile?.me?.tutorialSeen) || false
  const mobileCheck = useIsMobile()
  const tabletCheck = useIsTablet()
  const isMobile = mobileCheck || tabletCheck
  const dispatch = useDispatch()
  const router = useRouter()
  const me = useSelector(state => state.profile.me)
  const [showVerifyModal, setShowVerifyModal] = useState(false)
  const [showVoteModal, setShowVoteModal] = useState(false)
  const { active, officialUTCStartHour, recommendedDesigners, showFollowModal, showReward } = useSelector(state => state.checkIn)
  const { designGames } = useSelector(state => state.designGames)
  const { feed: templates } = useSelector(state => state.template)
  const { state: locationState } = useSelector(state => state.locationState)
  const { emailVerified } = me || {}

  const membershipInfo = getMembershipType(me)

  const { claimedTasks, tasksList, specialTaskList } = useDailyRewardsInfo()

  //OLD 2024 WHAT IS THIS FOR?
  //Used for 'Publish Template Design' task
  /*
  useEffect(() => {    
    dispatch(setAttr('template')({ addition: {} }))
    dispatch(fetchFeed('template')({ status: FEED_RESET }))
  }, [])
  */

  const handleClaimReward = taskId => {
    const isSpecialTask = specialTaskList.some(item => item.objectId === taskId)

    dispatch(claimDailyTaskReward(taskId, isSpecialTask))
    dispatch(claimWelcomeGiftReward(taskId))
  }

  const handleProfileClick = () => {
    router.push('/dm')
    dispatch(closeModal())
  }

  const handleClickCoins = () => {
    logEvent('show_purchase_coin', {
      content_type: 'purchase'
    })
    dispatch(setShowPurchaseCoins(true))
    dispatch(closeModal())
  }

  const handleTaskClick = useCallback(
    taskId => {
      const checkTemplate = async () => {
        let firstTemplate
        console.log(templates)
        if (templates?.length > 0) {
          firstTemplate = templates[0]
        } else {
          const res = await getTemplates()
          console.log(res)
          if (res[0]) firstTemplate = res[0]
        }
        console.log(firstTemplate)
        if (firstTemplate) router.push(`/create/template/${firstTemplate.objectId}`)
      }

      if (!emailVerified && taskId !== '8GORtOEbeF') {
        setShowVerifyModal(true)

        return
      }

      if (!['kmEXaEWYFG', 'jrY1WqDHjS'].includes(taskId)) {
        dispatch(closeModal())
      }

      switch (taskId) {
        case 'jrY1WqDHjS':
          setShowVoteModal(true)
          break
        case 'ffTFgusCfz':
          router.push('/create/challenge/daily')
          break
        case 'rcIEBNmaSr':
          router.push('/create/challenge/limited')
          break
        case 'dIEQgLxtpw':
          router.push('/create/challenge/newuser')
          break
        case 'kmEXaEWYFG':
          // open the Follow Designers modal when clicking on the follow designers task.
          dispatch(getRecommendedUsers())
          dispatch(openFollowModal())

          break
        case '1axAkTN3zc':
          router.push('/community/featured')
          break
        case 'Xr4CyGw6Jb':
          // open the main page when clicking on the like or virtual gift tasks.
          router.push('/community/featured')
          break
        case '8GORtOEbeF':
          // create username
          dispatch(setShowUsernameModal(true))
          break
        case 'KC4XeteGcx':
          const firstEvent = designGames.find(item => item.className === 'UserEvent' && item.status === 'active')

          if (firstEvent) {
            router.push(`/event/${firstEvent.objectId}`)
          } else {
            router.push('/challenges')
          }

          break
        case 'OUv6BrETWp':
          //2024
          checkTemplate()
          //const firstTemplate = templates[0]
          //router.push(`/create/template/${firstTemplate.objectId}`)
          break
        default:
          router.push('/community/featured')
          break
      }
    },
    [emailVerified, dispatch, router, recommendedDesigners, designGames, templates]
  )

  if (locationState?.data?.type === 'postSuccess') return null

  if (router.pathname.includes('/blog')) return null

  if (showFollowModal) {
    return <FollowDesigners />
  }

  if (router?.query?.showDailyRewards && router?.query?.showDailyRewards === 'false') return null

  if ((isTutorial && !tutorialSeen) || router.query.tutorial === 'true') return null
  if (!active || tasksList.length === 0) return null

  if (showReward) return <RewardModal />
  if (showVoteModal) return <VoteModal onClose={() => setShowVoteModal(false)} />

  const balancePlank = (
    <>
      <MyBalanceLabel>My Balance</MyBalanceLabel>
      <BalancePlank onClick={handleClickCoins}>
        <img src={dcoinIcon} alt="" />
        <span>{nFormatter(me?.numCoins || 0, 2)}</span> Dcoins
      </BalancePlank>
    </>
  )

  const countdownPlank = (
    <CountdownLabel isMember={membershipInfo.isMember}>
      Rewards will expire in <CheckInTimer UTCStartHour={officialUTCStartHour} />
    </CountdownLabel>
  )

  const membershipAds = (
    <MembershipAds>
      <div>
        <MembershipAdIcon />
        <span>Free Designing?</span>
      </div>
      <SubmitButton onClick={() => dispatch(setShowMembershipModal(true))}>Become a Member</SubmitButton>
    </MembershipAds>
  )

  return (
    <>
      <Container>
        <Dimmer />
        <ModalContainer>
          <ModalTitle>
            <img src={checkInImg} alt="" />
            Daily Rewards
          </ModalTitle>
          <CloseButton onClick={() => dispatch(closeModal())}>
            <img src={closeIcon} alt="Close" />
          </CloseButton>
          <UserInfoPanel>
            {!isMobile && (
              <MembershipPlank onClick={() => dispatch(setShowMembershipModal(true))}>
                {membershipInfo.isMember && <MemberPlank />}
                {!membershipInfo.isMember && <StarterPlank />}
              </MembershipPlank>
            )}
            <MobileWelcomeAndCountdown>
              <MobileWelcomeLine>
                <h2>{isMobile ? 'Welcome!' : 'Welcome Back!'}</h2>
                {isMobile && membershipInfo.isMember && <MemberPlank style={{ height: 32 }} />}
                {isMobile && !membershipInfo.isMember && <StarterPlank style={{ height: 32 }} />}
              </MobileWelcomeLine>
              {isMobile && countdownPlank}
            </MobileWelcomeAndCountdown>
            <AvatarAndName>
              <AvatarWrapper onClick={handleProfileClick}>
                <DMProfilePic pic={getUserAvatar(me)} level={me?.userLevel} startLevel={me?.userLevel} endLevel={me?.userLevel} autoPlay showLevelBar />
                {me?.funAccessoriesImageUrl && <AvatarAccessory alt="" src={me?.funAccessoriesImageUrl} loading="lazy" />}
              </AvatarWrapper>
              <Username onClick={handleProfileClick}>{me?.uniqueDisplayName || me?.username || ''}</Username>
            </AvatarAndName>
            {!isMobile && balancePlank}

            {!isMobile && countdownPlank}

            {!membershipInfo.isMember && membershipAds}
          </UserInfoPanel>
          <TasksContainer>
            <TaskListHeader>
              <TasksTitle>
                Today's Tasks <span>{claimedTasks}</span>/{tasksList.length + specialTaskList.length}
              </TasksTitle>
              {isMobile && <MobileBalancePlank>{balancePlank}</MobileBalancePlank>}
            </TaskListHeader>
            <TasksList>
              {specialTaskList.map(task => {
                let link = '/'

                switch (task.className) {
                  case 'UserEvent':
                    link = `/event/${task.objectId}`
                    break
                  case 'DesignContest':
                    link = `/challenges/${task.objectId}`
                    break
                  default:
                    break
                }

                return (
                  <SpecialTaskCard
                    onClick={() => {
                      router.push(link)
                      dispatch(closeModal())
                    }}
                    backdrop={task.taskBackdropUrl}
                  >
                    <TaskMetadata>
                      <TaskIcon inverted>
                        <img src={specialTaskIcon} alt="" />
                      </TaskIcon>
                      <div>
                        <TaskTitle>{task.taskTitle}</TaskTitle>
                        <div>
                          <ProgressBar inverted>
                            <ProgressInnerBar progress={0} />
                          </ProgressBar>
                          <ProgressLabel>0 / 1</ProgressLabel>
                        </div>
                        <BeforeText>Before {moment(task?.taskExpiresAt?.iso).format('MMM D, YYYY')}</BeforeText>
                      </div>
                    </TaskMetadata>
                    <TaskButton inverted onClick={() => {}}>
                      <img src={dcoinIcon} alt="" />
                      <span>Earn {task.taskCoinReward}</span>
                    </TaskButton>
                  </SpecialTaskCard>
                )
              })}
              {tasksList.map(task => {
                let buttonText = `Earn ${task.coinReward}`

                const isCompleted = task.completed && !task.claimed

                if (isCompleted) buttonText = `Claim ${task.coinReward}`
                if (task.completed && task.claimed) buttonText = `Claimed`

                const progress = task.completed || task.claimed ? 100 : Math.round((task.actionsDone / task.actionsRequired) * 100)

                const taskRequired = task.actionsRequired || 1
                const taskActionsDone = task.actionsDone > taskRequired ? taskRequired : task.actionsDone

                return (
                  <TaskCard
                    onClick={() => {
                      if (!task.claimed) handleTaskClick(task.objectId)
                    }}
                    key={task.objectId}
                  >
                    <TaskMetadata>
                      <TaskIcon>
                        <img src={TASK_ICONS[task.objectId]} alt="" />
                      </TaskIcon>
                      <div>
                        <TaskTitle>{task.title}</TaskTitle>
                        <div>
                          <ProgressBar>
                            <ProgressInnerBar progress={progress} />
                          </ProgressBar>
                          <ProgressLabel>
                            {taskActionsDone >= taskRequired ? taskRequired : taskActionsDone} / {taskRequired}
                          </ProgressLabel>
                        </div>
                      </div>
                    </TaskMetadata>
                    <TaskButton
                      claimed={task.claimed}
                      completed={isCompleted}
                      disabled={task.claimed}
                      onClick={e => {
                        if (!task.claimed && task.completed) {
                          e.stopPropagation()
                          handleClaimReward(task.objectId)
                        }
                      }}
                    >
                      {!task.claimed && <img src={dcoinIcon} alt="" />}
                      {task.claimed && <img src={checkmarkIcon} alt="" />}
                      <span>{buttonText}</span>
                    </TaskButton>
                  </TaskCard>
                )
              })}
            </TasksList>
          </TasksContainer>
        </ModalContainer>
      </Container>
      {showVerifyModal && <VerifyEmailSimpleModal reminder={true} onClose={() => setShowVerifyModal(false)} />}
    </>
  )
}
