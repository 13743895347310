import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import ModalOverlay from '../../common/ModalOverlay'
import coinIcon from '../../../assets/coin.svg'
import backIcon from '../../../assets/BackNavBtn.svg'
import { fetchPurchaseKeys } from '../../../redux/designerReducers/cartReducer'
import { sleep } from '../../../utils/sleep'
import { fetchPurchaseEffects } from '../../../redux/designerReducers/effectsReducer'
import { setShowPurchaseCoins } from '../../../redux/designerReducers/designerReducer'
import { fetchFeed } from '../../../redux/feedInfiniteSlice'
import { FEED_RESET } from '../../../redux/constants'

const Root = styled.div`
  background-color: #fff;
  width: min(90vw, 720px);
  height: min(770px, 90vh);
  border-radius: 10px;
  overflow-y: auto;
  background: #fbf3f3;

  @media only screen and (max-width: 600px) {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 0;

  font-family: Helvetica Neue;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.01em;

  & img {
    width: 20px;
    height: 20px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    padding: 20px 20px 0;
    & img {
      width: 18px;
      height: 18px;
    }
  }
`

const Back = styled.button`
  background: none;
  padding: 0;
  border: 0;
  background: url(${backIcon});
  width: 50px;
  height: 50px;
  background-size: 100%;
`

const Title = styled.div`
  font-weight: 700;
  font-family: Helvetica Neue;
  font-size: 32px;
  margin: 50px 0 80px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 28px;
    margin: 40px 0 60px;
  }
`

const Row = styled.div`
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding: 0 70px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 25px;
    padding: 0 25px;
  }
`

const CardContainer = styled.div`
  padding: 0 70px;

  @media only screen and (max-width: 600px) {
    padding: 0 25px;
  }
`

const Submit = styled.button`
  border: 0;
  width: 250px;
  height: 50px;
  border-radius: 25px;
  background: #ff5e6d;
  margin: auto;
  color: #fff;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CreditCardInput = styled.input.attrs(props => ({
  className: 'StripeElement'
}))`
  width: 100%;
  font-family: Helvetica Neue;
  font-size: 14px;
  box-sizing: border-box;
`

const ErrorContainer = styled.div`
  margin-bottom: 15px;
  color: #ff5e6d;
  height: 25px;
`

const PolicyWarning = styled.p`
  text-align: center;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;

  & a {
    color: #427bf7;
    text-decoration: underline;
  }
`

const formatPrice = raw => {
  return (raw * 0.01).toFixed(2)
}

const PaymentModal = ({
  onSelect,
  createCustomer,
  selected,
  createSubscription,
  createRevenueCatReceipt,
  createRevenueCatPurchaseReceipt,
  userid,
  getPaymentIntentCoins,
  clientSecret,
  onSuccess
}) => {
  const dispatch = useDispatch()
  const me = useSelector(state => state.profile.me)
  const [cardholder, setCardholder] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [err, setErr] = useState(null)
  const [payBtnTitle, setPayBtnTitle] = useState('Pay Now')
  const [type, setType] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  const validate = () => {
    if (!cardholder) {
      setErr('Cardholder name is incomplete.')
      return false
    }
    return true
  }

  useEffect(() => {
    setType('')
    setPayBtnTitle('Pay Now')
    setIsSubmitting(true)
    if (selected.nickname === 'membership') {
      setType('membership')
      createCustomer().then(r => {
        setIsSubmitting(false)
      })
    } else if (selected.nickname === 'coins') {
      setType('coins')
      getPaymentIntentCoins(selected.id).then(r => {
        setIsSubmitting(false)
      })
    } else if (selected.className === 'ItemBundle') {
      setType('bundle')
      getPaymentIntentCoins(selected.product.id).then(() => {
        setIsSubmitting(false)
      })
    }
  }, [selected])

  const handleChange = () => {
    setErr(null)
  }

  const handleClose = () => {
    onSelect(null)

    if (selected?.closeMode === 'close') {
      dispatch(setShowPurchaseCoins(false))

      return
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErr(null)
    setPayBtnTitle('Processing')
    if (isSubmitting) return

    if (validate()) {
      setIsSubmitting(true)
      if (!stripe || !elements) return

      if (type === 'membership') {
        handleMemberSubmit()
      } else if (type === 'coins') {
        handleCoinSubmit()
      } else if (type === 'bundle') {
        handleBundleSubmit()
      }
    } else {
      setPayBtnTitle('Pay Now')
    }
  }

  const handleBundleSubmit = async () => {
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardholder
        }
      }
    })

    if (payload.error || !payload?.paymentIntent) {
      setErr(`Payment failed ${payload.error.message}`)
      setIsSubmitting(false)
      setPayBtnTitle('Try Again')
    } else {
      await sleep(2000)
      await dispatch(fetchPurchaseKeys())
      await dispatch(fetchPurchaseEffects())
      await dispatch(fetchFeed('purchased')({ status: FEED_RESET }))

      setErr(null)
      setIsSubmitting(false)
      setPayBtnTitle('Processed')
      onSuccess(selected)
    }
  }

  const handleCoinSubmit = async ev => {
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardholder
        }
      }
    })

    if (payload.error) {
      setErr(`Payment failed ${payload.error.message}`)
      setIsSubmitting(false)
      setPayBtnTitle('Try Again')
    } else {
      setErr(null)
      setIsSubmitting(false)
      setPayBtnTitle('Processed')
      onSuccess(selected)
    }
  }

  const handleMemberSubmit = async ev => {
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: cardholder
      }
    })

    if (payload.error) {
      setErr(`${payload.error.message}`)
      setIsSubmitting(false)
      setPayBtnTitle('Try Again')
    } else {
      setErr(null)
      createSubscription(payload.paymentMethod.id, selected.price.id).then(
        r => {
          createRevenueCatReceipt(userid, r.id).then(rc => {
            setIsSubmitting(false)
            setPayBtnTitle('Processed')
            onSuccess(selected)
          })
        }
      )
    }
  }

  const price = `$${formatPrice(
    type === 'bundle'
      ? selected.product.price?.unit_amount
      : selected.price?.unit_amount
  )}`

  return (
    <ModalOverlay fullscreen onClose={handleClose}>
      <Root>
        <Header>
          <Back onClick={handleClose} />
          <span>
            DCoin Balance: <img src={coinIcon} alt="dcoin" /> {me?.numCoins}
          </span>
        </Header>
        <Title>Make A Payment</Title>
        <Row>
          <span>
            {selected.name || 'Bundle'}{' '}
            {type === 'membership' ? 'Membership' : ''}
          </span>
          <span>{price}</span>
        </Row>
        <Row>
          <span>Total:</span>
          <span>{price}</span>
        </Row>
        <form>
          <CardContainer>
            <CreditCardInput
              type="text"
              placeholder="Cardholder Name"
              value={cardholder}
              onChange={e => {
                setErr(null)
                setCardholder(e.target.value)
              }}
            />
          </CardContainer>
          <CardContainer>
            <CardElement />
            {err && <ErrorContainer>{err}</ErrorContainer>}
          </CardContainer>
          <Submit onClick={handleSubmit}>{payBtnTitle}</Submit>
        </form>
        <PolicyWarning>
          By proceeding you agree to the{' '}
          <a href="/terms" target="_blank">
            Terms Of Use
          </a>{' '}
          and{' '}
          <a href="/policy" target="_blank">
            Privacy Policy
          </a>
          .
        </PolicyWarning>
      </Root>
    </ModalOverlay>
  )
}

export default PaymentModal
